import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {DocumentActions, FEATURE} from '../../redux/actions/documents';
import {CommandActions as DocumentCommandActions} from '../../redux/actions/document';
import {CommandActions as DocumentsCommandActions, DocumentActions as DocumentDocumentsActions} from '../../redux/actions/documents';
import {CommandActions as ModalCommandActions} from '../../redux/actions/modal';
import {CommandActions as FiltersCommandActions} from '../../redux/actions/filters';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import CommandBar from './command_bar/index'
import Notification from '../notification/index';
import { getNotification } from '../../redux/selectors/notification';
import { getFilterPanelOpened } from '../../redux/reducers/ui';
import { getLoader } from '../../redux/selectors/loader';
import { IColumn, MessageBarType, ProgressIndicator, Spinner, SpinnerSize, Selection } from 'office-ui-fabric-react';
import { getClassNames } from './index.classNames';
import { getColumns } from '../documents/columns';
import SelectedFilters from '../filtersPanel/filters/selectedFilters';
import _ from 'lodash';
import { getOrderColumn, getTotal } from '../../redux/reducers/documents';
import DocumentModal from '../modals/document'
import DocumentsGrid from '../documents/index'
import { getSelectedProjectUserActions } from '../../redux/selectors/auth';
import { getFilters } from '../../redux/reducers/filters';
import { Filters } from '../../models/filters';
import { getNotificationWidth } from '../../redux/selectors/ui';
import CalloutFilters from '../filtersPanel/callout/index';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import DownloadFiles from '../documents/functionalities/downloadFiles';
import DownloadComments from '../documents/functionalities/downloadComments';
import DownloadExcelReport from '../documents/functionalities/downloadExcelReport';
import { InternalRoleActionsType, ExternalRoleActionsType, getTheme } from '../../utils/costants';
import { getHistoryName } from '../../utils/functions';
import { getContextualDocuments } from '../../redux/selectors/documents';
import { Document } from '../../models/document';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getFolders } from '../../redux/reducers/sharedData';
import { getEmail } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { getDarkModeEnabled } from '../../redux/reducers/project';
let GenericModal = GenericModalGenerator();

export interface SearchProps extends RouteComponentProps, WithTranslation {
    loading: boolean,
    loadingContent: boolean,
    loadingScroll: boolean,
    documents: Document[]
    filterPanelOpened: boolean, 
    showNotification: boolean, 
    type: MessageBarType, 
    message: string,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    filters: Filters,
    showCallout: boolean,
    orderColumn: {name: string, direction: string },
    total: number,
    notificationWidth: string,
    email?: string,
    darkModeEnabled: boolean,
    setSelectedItemsIds: typeof DocumentActions.setSelectedItemsIds,
    getDocumentAndOpenModal: typeof DocumentCommandActions.getDocumentAndOpenModal,
    showOrHideCallout: typeof FiltersCommandActions.showOrHideCallout,
    openGenericModal: typeof ModalCommandActions.openModal,
    downloadOrShowFile: typeof DocumentsCommandActions.downloadOrShowFile,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
    setSortColumn: typeof DocumentDocumentsActions.setSortColumn,
    downloadFiles: typeof DocumentsCommandActions.downloadFiles,
    downloadComments: typeof DocumentsCommandActions.downloadComments,
    downloadExcelReport: typeof DocumentsCommandActions.downloadExcelReport,
    newRevision: typeof DocumentsCommandActions.newRevision
}
class Search extends React.Component<SearchProps> {
    constructor(props: SearchProps) {
        super(props);   
    }

    getKey = (item: any, index?: number): string => {
        return item.id;
    }

    getFilters = (): Filters => {
        const { filters } = this.props;
        return filters;
    }

    getFilterPanel = (): boolean => {
        const { filterPanelOpened } = this.props;
        return filterPanelOpened;
    }

    onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { fetchDocuments, setSortColumn } = this.props; 
        setSortColumn({colName: column.fieldName as string, direction: (column.isSortedDescending) ? 'asc' : 'desc', feature: FEATURE});
        //this.selection.setAllSelected(false);    
        fetchDocuments({});
    };

    selection = new Selection({		
        getKey: this.getKey,
		onSelectionChanged: () => {
            let rows = this.selection.getSelection() as any;
            const {setSelectedItemsIds} = this.props;
            let ids = (rows) ? _.map(rows, (r: Document) => {return r.id as number}) : [];
            setSelectedItemsIds({ids: _.map(ids, id => id.toString()), feature: FEATURE}) 
		}
    });

    render(){
        const {loading, loadingContent, showNotification, type, message, filterPanelOpened, orderColumn, loadingScroll, userProjectActions, total, documents, t, email, darkModeEnabled,
               history, notificationWidth, getDocumentAndOpenModal, showOrHideCallout, downloadOrShowFile, openGenericModal, newRevision,
               downloadFiles, downloadComments, downloadExcelReport} = this.props;
        const styles = getClassNames(filterPanelOpened);
        const historyName = getHistoryName(history);
        const showWarningMessage = !((documents.length === 0 && total < 100) || (documents.length > 0 && documents.length < 100));
        const canRead = _.find(userProjectActions, a => a === 'ReadCreateRevision') ? true : false;
        const columns = getColumns(email as string, t, canRead, false, this.selection, this.getFilterPanel, getDocumentAndOpenModal, this.getFilters, showOrHideCallout, downloadOrShowFile, this.onColumnClick, orderColumn, historyName, openGenericModal, undefined, undefined, _.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId'), getTheme(darkModeEnabled));
        return (
            <>
                <CommandBar userProjectActions={userProjectActions} loading={loading} loadingContent={loadingContent} loadingScroll={loadingScroll}/>
                <div style={{position: 'absolute', width: notificationWidth, top: 99, zIndex: 10}}>
                    {!loading && !loadingContent && <Notification name={'createRevision'} show={showNotification} type={type} message={message}/>}
                </div>
                <SelectedFilters/>
                <ProgressIndicator styles={{root: {height: 10}}} progressHidden={ !loadingContent || loading }/> 
                {loading && <Spinner size={SpinnerSize.large} style={{marginTop: 50}}></Spinner>}
                {!loading &&
                    <div className={styles.resultContainer}>
                        <DocumentsGrid selection={this.selection} columns={columns} loadingScroll={loadingScroll}/>
                        <DocumentModal userProjectActions={userProjectActions}/>
                    </div>
                }
                <CalloutFilters/>
                <GenericModal 
                    id={'fileName'}
                    title={t('Download files')}
                    message={(!showWarningMessage) ? '' : t('Recommended maximum download: 100 documents. Click OK to proceed. Note: Exceeding this may increase download time.')}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadFiles()}}
                    btnConfirmText={t('Download')}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadFiles/>}
                    disableConfirm={loadingContent}     
                />
                <GenericModal 
                    id={'comments'}
                    title={t('Download comments')}
                    message={''}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadComments()}}
                    btnConfirmText={t('Download')}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadComments/>}
                    disableConfirm={loadingContent}     
                />
                <GenericModal 
                    id={'excelReport'}
                    title={t('Download Excel report')}
                    message={''}                 
                    onClose={() => {}}
                    onConfirm={() => {downloadExcelReport()}}
                    btnConfirmText={t('Download')}
                    btnCloseText={t('Cancel')}   
                    customComponent={<DownloadExcelReport/>}
                    disableConfirm={loadingContent}          
                />
                <GenericModal 
                    id={'revision'}
                    title={t('New revision')}
                    message={t('Do you want to create a new revision for this document?')}                 
                    onClose={() => {}}
                    onConfirm={() => {newRevision()}}
                    btnConfirmText={t('Yes')}
                    btnCloseText={t('No')}   
                    disableConfirm={loadingContent}    
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loading: (state) => getLoader('createRevision')(state).loading,
    loadingContent: (state) => getLoader('createRevision')(state).loadingContent,
    loadingScroll: (state) => getLoader('createRevision')(state).loadingScroll,
    showNotification: (state) => getNotification('createRevision')(state).show,
    type: (state) => getNotification('createRevision')(state).type,
    message: (state) => getNotification('createRevision')(state).message,
    filterPanelOpened: getFilterPanelOpened,
    userProjectActions: getSelectedProjectUserActions,
    filters: getFilters,
    orderColumn: getOrderColumn,
    total: getTotal,
    documents: getContextualDocuments,
    notificationWidth: getNotificationWidth,
    folders: getFolders,
    email: getEmail,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setSelectedItemsIds: DocumentActions.setSelectedItemsIds,
        getDocumentAndOpenModal: DocumentCommandActions.getDocumentAndOpenModal,
        showOrHideCallout: FiltersCommandActions.showOrHideCallout,
        openGenericModal: ModalCommandActions.openModal,
        downloadOrShowFile: DocumentsCommandActions.downloadOrShowFile,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        setSortColumn: DocumentDocumentsActions.setSortColumn,
        downloadFiles: DocumentsCommandActions.downloadFiles,
        downloadComments: DocumentsCommandActions.downloadComments,
        downloadExcelReport: DocumentsCommandActions.downloadExcelReport,
        newRevision: DocumentsCommandActions.newRevision
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Search)));