export const languages: { code: string, description: string }[] = [
    { code: 'af', description: 'Africans' },
    { code: 'ar', description: 'Arabic' },
    { code: 'bg', description: 'Bulgarian' },
    { code: 'ca', description: 'Catalan' },
    { code: 'cs', description: 'Czech' },
    { code: 'da', description: 'Danish' },
    { code: 'de', description: 'German' },
    { code: 'el', description: 'Greek' },
    { code: 'en', description: 'English' },
    { code: 'es', description: 'Spanish' },
    { code: 'et', description: 'Estonian' },
    { code: 'fi', description: 'Finnish' },
    { code: 'fr', description: 'French' },
    { code: 'he', description: 'Hebrew' },
    { code: 'hi', description: 'Hindi' },
    { code: 'hr', description: 'Croatian' },
    { code: 'hu', description: 'Hungarian' },
    { code: 'id', description: 'Indonesian' },
    { code: 'is', description: 'Icelandic' },
    { code: 'it', description: 'Italian' },
    { code: 'ja', description: 'Japanese' },
    { code: 'kk', description: 'Kazakh' },
    { code: 'ko', description: 'Korean' },
    { code: 'lt', description: 'Lithuanian' },
    { code: 'lv', description: 'Latvian' },
    { code: 'ms', description: 'Malay' },
    { code: 'nl', description: 'Dutch' },
    { code: 'no', description: 'Norwegian' },
    { code: 'pl', description: 'Polish' },
    { code: 'pt', description: 'Portuguese' },
    { code: 'ro', description: 'Romanian' },
    { code: 'ru', description: 'Russian' },
    { code: 'sh', description: 'Serbo-croatian' },
    { code: 'sk', description: 'Slovakian' },
    { code: 'sl', description: 'Slovenian' },
    { code: 'sr', description: 'Serbian' },
    { code: 'sv', description: 'Swedish' },
    { code: 'th', description: 'Thai' },
    { code: 'tr', description: 'Turkish' },
    { code: 'uk', description: 'Ukrainian' },
    { code: 'vi', description: 'Vietnamese' },
    { code: 'zh', description: 'Chinese' }
];

export const translateLanguages: {code: string, description: string} [] = [
    { code: 'en', description: 'English'},
    { code: 'de', description: 'German' },
    { code: 'fr', description: 'French' },
    { code: 'it', description: 'Italian'},
    { code: 'ru', description: 'Russian'}
];



export const translations = {
    en: {
        translation: {
            "Home": "Home",
            "Dashboard": "Dashboard",
            "Engineering Orders": "Engineering Orders",
            "Supplier material": "Supplier material",
            "Create Revision": "Create Revision",
            "Administration": "Administration",
            "Quality Check": "Quality Check",
            "Publish": "Publish",
            "Pending": "Pending",
            "Projects configuration": "Projects configuration",
            "Folders": "Folders",
            "Client Number": "Client Number",
            "Generators": "Generators",
            "Filters": "Filters",
            "Status": "Status",
            "Select a Status": "Select a Status",
            "From": "From",
            "To": "To",
            "SMS Doc.Number": "SMS Doc.Number",
            "Press enter to search": "Press enter to search",
            "Sheet": "Sheet",
            "Revision": "Revision",
            "Agile Number": "Agile Number",
            "TD Number": "TD Number",
            "Publication Number": "Publication Number",
            "Remove PBS": "Remove PBS",
            "Type": "Type",
            "Discipline": "Discipline",
            "Select a discipline": "Select a discipline",
            "Doc Type": "Doc Type",
            "Select a doc type": "Select a doc type",
            "Eng.Phase": "Eng.Phase",
            "Select an eng.phase": "Select an eng.phase",
            "Entity": "Entity",
            "Select an entity": "Select an entity",
            "Purpose": "Purpose",
            "Select a purpose": "Select a purpose",
            "PW filename": "PW filename",
            "commented": "commented",
            "Commented": "Commented",
            "Show all indexes": "Show all indexes",
            "Show TD cover page": "Show TD cover page",
            "Change Status": "Change Status",
            "You are going to change the status of the following document": "You are going to change the status of the following document",
            "You are going to change the status of the following documents": "You are going to change the status of the following documents",
            "The new status will be": "The new status will be",
            "Save": "Save",
            "Cancel": "Cancel",
            "Edit": "Edit",
            "Supplier doc number": "Supplier doc number",
            "This field is mandatory": "This field is mandatory",
            "Internal use only": "Internal use only",
            "Edit document": "Edit document",
            "Upload": "Upload",
            "Selected document": "Selected document",
            "Selected documents": "Selected documents",
            "Upload  files": "Upload  files",
            "Drop the files here, or click to select file to upload.": "Drop the files here, or click to select file to upload.",
            "File type": "File type",
            "Attachment": "Attachment",
            "Comment": "Comment",
            "Delete": "Delete",
            "The document": "The document",
            "will be deleted. Are you sure?": "will be deleted. Are you sure?",
            "Attention": "Attention",
            "Download files": "Download files",
            "There are too many documents to download. Please select a number of documents less than 20": "There are too many documents to download. Please select a number of documents less than 20",
            "Recommended maximum download: 100 documents. Click OK to proceed. Note: Exceeding this may increase download time.": "Recommended maximum download: 100 documents. Click OK to proceed. Note: Exceeding this may increase download time.",
            "Download only AGILE files": "Download only AGILE files",
            "Download comments": "Download comments",
            "Report": "Report",
            "Refresh": "Refresh",
            "Approved": "Approved",
            "Supplier approved": "Supplier approved",
            "Approved with comments": "Approved with comments",
            "Canceled": "Canceled",
            "Checked": "Checked",
            "Declined with comments": "Declined with comments",
            "Supplier declined": "Supplier declined",
            "Published": "Published",
            "Supplier published": "Supplier published",
            "Ready for Exodbox": "Ready for Exodbox",
            "Saved to Agile": "Saved to Agile",
            "Ready for publishing": "Ready for publishing",
            "Uploaded": "Uploaded",
            "Supplier uploaded": "Supplier uploaded",
            "Waiting for Agile": "Waiting for Agile",
            "Reserved": "Reserved",
            "Details": "Details",
            "Titles": "Titles",
            "Files": "Files",
            "Comments": "Comments",
            "Total sheet": "Total sheet",
            "Format": "Format",
            "Scale": "Scale",
            "Customer revision": "Customer revision",
            "Engineering Element": "Engineering Element",
            "Td Subject": "Td Subject",
            "Item Number": "Item Number",
            "Material Number": "Material Number",
            "Designation": "Designation",
            "Appendix": "Appendix",
            "Internal order number": "Internal order number",
            "Customer": "Customer",
            "Site": "Site",
            "Project": "Project",
            "Title": "Title",
            "Other Revisions": "Other Revisions",
            "(LATEST VERSION)": "(LATEST VERSION)",
            "Publish to D3": "Publish to D3",
            "Decline": "Decline",
            "New": "New",
            "documents": "documents",
            "Administration panel": "Administration panel",
            "Export users": "Export users",
            "New external user": "New external user",
            "Internal users": "Internal users",
            "External users": "External users",
            "Email": "Email",
            "Role": "Role",
            "Help": "Help",
            "Changelog": "Changelog",
            "All notable changes to this project will be documented in this file.": "All notable changes to this project will be documented in this file.",
            "Logout": "Logout",
            "Settings": "Settings",
            "Deleting": "Deleting",
            "Cannot upload these files": "Cannot upload these files",
            "Please retry": "Please retry",
            "Files uploaded successfully": "Files uploaded successfully",
            "Status changed and files uploaded successfully": "Status changed and files uploaded successfully",
            "File uploaded successfully": "File uploaded successfully",
            "Document edited successfully": "Document edited successfully",
            "Documents edited successfully": "Documents edited successfully",
            "deleted successfully": "deleted successfully",
            "Please refresh the page": "Please refresh the page",
            "Cannot get the documents for this project": "Cannot get the documents for this project",
            "Cannot get updated document": "Cannot get updated document",
            "Cannot upload this file": "Cannot upload this file",
            "Cannot change the status": "Cannot change the status",
            "Cannot edit this document": "Cannot edit this document",
            "Cannot delete this file": "Cannot delete this file",
            "Cannot delete": "Cannot delete",
            "Cannot download comments": "Cannot download comments",
            "Cannot get all document ids": "Cannot get all document ids",
            "Downloading comments": "Downloading comments",
            "Downloading report": "Downloading report",
            "Downloading template": "Downloading template",
            "Download completed": "Download completed",
            "Cannot download excel report": "Cannot download excel report",
            "User permissions saved succesfully": "User permissions saved succesfully",
            "User created succesfully": "User created succesfully",
            "Cannot fetch internal app roles": "Cannot fetch internal app roles",
            "Cannot fetch external app roles": "Cannot fetch external app roles",
            "Cannot fetch internal users": "Cannot fetch internal users",
            "Cannot fetch external users": "Cannot fetch external users",
            "Cannot save users": "Cannot save users",
            "Cannot save internal users": "Cannot save internal users",
            "Cannot save external users": "Cannot save external users",
            "Cannot create new external user": "Cannot create new external user",
            "Cannot download user report": "Cannot download user report",
            "Comment added successfully": "Comment added successfully",
            "Cannot get the document details": "Cannot get the document details",
            "Cannot get document revisions": "Cannot get document revisions",
            "Cannot get the document": "Cannot get the document",
            "Cannot add new comment": "Cannot add new comment",
            "Cannot download this file": "Cannot download this file",
            "Cannot get publication data": "Cannot get publication data",
            "Cannot get client data": "Cannot get client data",
            "Cannot get final purposes data": "Cannot get final purposes data",
            "Project saved successfully": "Project saved successfully",
            "Cannot get projects list": "Cannot get projects list",
            "Cannot get project purposes": "Cannot get project purposes",
            "Cannot save this project": "Cannot save this project",
            "Please select a page": "Please select a page",
            "Please select a project": "Please select a project",
            "Import zip": "Import zip",
            "Replace file": "Replace file",
            "Expand panel": "Expand panel",
            "selected": "selected",
            "The file": "The file",
            "Publication Status": "Publication Status",
            "Client Status": "Client Status",
            "Final Purposes": "Final Purposes",
            "Reserved Numbers" : "Reserved Numbers",
            "Public.Number": "Public.Number",
            "Upload files": "Upload files",
            "Download" : "Download",
            "Download Excel report": "Download Excel report",
            "Select a status": "Select a status",
            "Client number": "Client number",
            "Select a type": "Select a type",
            "Select an Eng.phase": "Select an Eng.phase",
            "PW Filename": "PW Filename",
            "File name": 'File name',
            "Select the customers": "Select the customers",
            "Select the customer": "Select the customer",
            "If you do not select any customer": "If you do not select any customer",
            "the document will be published to all the customer related to this project": "the document will be published to all the customer related to this project",
            "the documents will be published to all the customer related to this project": "the documents will be published to all the customer related to this project",
            "You are going to add to a folder the following document": "You are going to add to a folder the following document",
            "You are going to add to a folder the following documents": "You are going to add to a folder the following documents",
            "You are going to remove from a folder the following document": "You are going to remove from a folder the following document",
            "You are going to remove from a folder the following documents": "You are going to remove from a folder the following documents",
            "Select a folder": "Select a folder",
            "New subfolder": "New subfolder",
            "Edit subfolder": "Edit subfolder",
            "Remove subfolder": "Remove subfolder",
            "Only one file can be uploaded": "Only one file can be uploaded",
            "Please select a .zip file": "Please select a .zip file",
            "Folder name": "Folder name",
            "Folder path": "Folder path",
            "Number of sheets": "Number of sheets",
            "Remove Folder": "Remove Folder",
            "Partner": "Partner",
            "Published to": "Published to",
            "Select a customer": "Select a customer",
            "Folder created  successfully": "Folder created  successfully",
            "Folder edited  successfully": "Folder edited  successfully",
            "Folder deleted successfully": "Folder deleted successfully",
            "Documents successfully added to the folder": "Documents successfully added to the folder",
            "Documents successfully removed from the folder": "Documents successfully removed from the folder",
            "Cannot create this folder": "Cannot create this folder",
            "Cannot edit this folder": "Cannot edit this folder",
            "Cannot add documents to this folder": "Cannot add documents to this folder",
            "Cannot remove documents from this folder": "Cannot remove documents from this folder",
            "Mail": "Mail",
            "I would like to receive notifications about": "I would like to receive notifications about",
            "Notifications": "Notifications",
            "Status changed in EXOD2": "Status changed in EXOD2",
            "Supplier publish in AGILE EXOD2": "Supplier publish in AGILE EXOD2",
            "Supplier publish in EXOD2": "Supplier publish in EXOD2",
            "Reserved numbers created in EXOD2": "Reserved numbers created in EXOD2",
            "Publish document in EXOD2": "Publish document in EXOD2",
            "New comments in EXOD2": "New comments in EXOD2",
            "Client checker to approve in EXOD2": "Client checker to approve in EXOD2",
            "Notification when a TD is created": "Notification when a TD is created",
            "You are going to delete the folder": "You are going to delete the folder",
            "Do you want to create a new revision for this document?": "Do you want to create a new revision for this document?"
        } 
    },
    ru: {
        translation: {
            "Home": "Главная страница",
            "Dashboard": "Панель управления",
            "Engineering Orders": "Технические задания",
            "Supplier material": "Материал поставщика",
            "Create Revision": "Создать редакцию",
            "Administration": "Администрация",
            "Quality Check": "Проверка качества",
            "Publish": "Опубликовать",
            "Pending": "В ожидании",
            "Projects configuration": "Конфигурация проекта",
            "Folders": "Папки",
            "Client Number": "Номер заказчика",
            "Generators": "Генераторы",
            "Filters": "Фильтры",
            "Status": "Статус",
            "Select a Status": "Выбрать статус",
            "From": "От",
            "To": "До",
            "SMS Doc.Number": "Номер документа",
            "Press enter to search": "Для поиска нажмите клавишу Enter ",
            "Sheet": "Лист",
            "Revision": "Редакция",
            "Agile Number": "Номер в приложении Agile",
            "TD Number": "Номер TD",
            "Publication Number": "Номер публикации",
            "Remove PBS": "Убрать PBS",
            "Type": "Тип",
            "Discipline": "Раздел",
            "Select a discipline": "Выбрать раздел",
            "Doc Type": "Тип документа",
            "Select a doc type": "Выбрать тип документа",
            "Eng.Phase": "Этап инжиниринга",
            "Select an eng.phase": "Выбрать этап инжиниринга",
            "Entity": "Субъект",
            "Select an entity": "Выбрать субъект",
            "Purpose": "Цель",
            "Select a purpose": "Выбрать цель",
            "PW filename": "Имя файла PW",
            "commented": "С замечаниями",
            "Commented": "С замечаниями",
            "Show all indexes": "Показать все версии",
            "Show TD cover page": "Показать титульную страницу TD",
            "Change Status": "Изменить статус",
            "You are going to change the status of the following document": "Вы хотите изменить статус следующего документа",
            "You are going to change the status of the following documents": "Вы хотите изменить статус следующего документы",
            "The new status will be": "Новый статус будет…",
            "Save": "Сохранить",
            "Cancel": "Отменить",
            "Edit": "Редактировать",
            "Supplier doc number": "Номер документа поставщика",
            "This field is mandatory": "Это поле является обязательным",
            "Internal use only": "Для внутреннего пользования",
            "Edit document": "Редактировать документ",
            "Upload": "Выложить",
            "Selected document": "Выбранный документ",
            "Selected documents": "Выбранный документы",
            "Upload  files": "Выложить файлы",
            "Drop the files here, or click to select file to upload.": "Переместите файлы сюда или кликните кнопкой мыши, чтобы выбрать файл для выгрузки.",
            "File type": "Тип файла",
            "Attachment": "Приложение",
            "Comment": "Комментировать",
            "Delete": "Удалить",
            "The document": "Документ",
            "will be deleted. Are you sure?": "будет удалён. Вы уверены?",
            "Attention": "Внимание",
            "Download files": "Загрузить файлы",
            "There are too many documents to download. Please select a number of documents less than 20": "Слишком много документов для загрузки. Выберите количество документов менее 20",
            "Download only AGILE files": "Загружать только файлы AGILE",
            "Download comments": "Загрузить замечания",
            "Report": "Отчет",
            "Refresh": "Обновить",
            "Approved": "Утверждено",
            "Supplier approved": "Поставщик утверждён",
            "Approved with comments": "Утверждено с замечаниями",
            "Canceled": "Отменено",
            "Checked": "Проверено",
            "Declined with comments": "Отклонено с замечаниями",
            "Supplier declined": "Поставщик отклонён",
            "Published": "Опубликовано",
            "Supplier published": "Поставщик опубликован",
            "Ready for Exodbox": "Готово для отправки в ящик Exodbox",
            "Saved to Agile": "Сохранено в Agile",
            "Ready for publishing": "Готово к публикации",
            "Uploaded": "Выложено",
            "Supplier uploaded": "Поставщик выложен",
            "Waiting for Agile": "В ожидании чертежей из Agile",
            "Reserved": "Зарезервировано",
            "Details": "Подробная информация",
            "Titles": "Названия",
            "Files": "Файлы",
            "Comments": "Замечания",
            "Total sheet": "Сводный лист",
            "Format": "Формат",
            "Scale": "Масштабирование",
            "Customer revision": "Редакция заказчика",
            "Engineering Element": "Технический элемент",
            "Td Subject": "Предмет Td",
            "Item Number": "Номер позиции",
            "Material Number": "Номер материала",
            "Designation": "Обозначение",
            "Appendix": "Приложение",
            "Internal order number": "Внутренний номер заказа",
            "Customer": "Заказчик",
            "Site": "Объект",
            "Project": "Проект",
            "Title": "Название",
            "Other Revisions": "Другие редакции",
            "(LATEST VERSION)": "(ПОСЛЕДНЯЯ ВЕРСИЯ)",
            "Publish to D3": "Опубликовать в D3",
            "Decline": "Отклонить",
            "New": "Новые",
            "documents": "документы",
            "Administration panel": "Административная панель",
            "Export users": "Экспортировать пользователей",
            "New external user": "Новый внешний пользователь",
            "Internal users": "Внутренние пользователи",
            "External users": "Внешние пользователи",
            "Email": "Эл. почта",
            "Role": "Роль",
            "Help": "Помощь",
            "Changelog": "Журнал изменений проекта",
            "All notable changes to this project will be documented in this file.": "Все значительные изменения в этом проекте будут задокументированы в этом файле.",
            "Logout": "Выход из системы",
            "Settings": "Установочные параметры",
            "Deleting": "Удаление",
            "Cannot upload these files": "Невозможно выложить эти файлы",
            "Please retry": "Повторите попытку",
            "Files uploaded successfully": "Файлы выложены успешно",
            "Status changed and files uploaded successfully": "Статус изменён, и файлы выложены успешно",
            "File uploaded successfully": "Файл выложен успешно",
            "Document edited successfully": "Документ успешно отредактирован",
            "Documents edited successfully": "Документы успешно отредактированы",
            "deleted successfully": "Успешно удалено",
            "Please refresh the page": "Обновите страницу",
            "Cannot get the documents for this project": "Не удается получить документы по этому проекту",
            "Cannot get updated document": "Невозможно получить обновленный документ",
            "Cannot upload this file": "Не удается выложить этот файл",
            "Cannot change the status": "Не удается изменить статус",
            "Cannot edit this document": "Невозможно редактировать этот документ",
            "Cannot delete this file": "Не удается удалить данный файл",
            "Cannot delete": "Невозможно удалить",
            "Cannot download comments": "Не удается загрузить замечания",
            "Cannot get all document ids": "Невозможно получить все идентификаторы документов",
            "Downloading comments": "Загрузка замечаний",
            "Downloading report": "Загрузка отчета",
            "Downloading template": "Загрузка шаблона",
            "Download completed": "Загрузка завершена",
            "Cannot download excel report": "Невозможно загрузить отчет в формате excel",
            "User permissions saved succesfully": "Полномочия пользователя сохранены успешно",
            "User created succesfully": "Пользователь создан успешно",
            "Cannot fetch internal app roles": "Не удается получить роли внутренних приложений",
            "Cannot fetch external app roles": "Не удается получить роли внешних приложений",
            "Cannot fetch internal users": "Не удается получить внутренних пользователей",
            "Cannot fetch external users": "Не удается получить внешних пользователей",
            "Cannot save users": "Не удается сохранить пользователей",
            "Cannot save internal users": "Не удается сохранить внутренних пользователей",
            "Cannot save external users": "Не удается сохранить внешних пользователей",
            "Cannot create new external user": "Не удается создать нового внешнего пользователя",
            "Cannot download user report": "Невозможно загрузить отчет пользователя",
            "Comment added successfully": "Комментарий добавлен успешно",
            "Cannot get the document details": "Не удается получить реквизиты документа",
            "Cannot get document revisions": "Невозможно получить редакции документа",
            "Cannot get the document": "Не удается получить документ",
            "Cannot add new comment": "Невозможно добавить новое замечание",
            "Cannot download this file": "Не удается загрузить этот файл",
            "Cannot get publication data": "Невозможно получить данные о публикации",
            "Cannot get client data": "Невозможно получить данные заказчика",
            "Cannot get final purposes data": "Невозможно получить данные о конечных целях",
            "Project saved successfully": "Проект успешно сохранен",
            "Cannot get projects list": "Невозможно получить список проектов",
            "Cannot get project purposes": "Не удается получить цели проекта",
            "Cannot save this project": "Невозможно сохранить этот проект",
            "Please select a page": "Выберите страницу",
            "Please select a project": "Выберите проект",
            "Import zip": "Импортировать zip-файл",
            "Replace file": "Заменить файл",
            "Expand panel": "Развернуть панель",
            "selected": "выбрано",
            "The file": "Файл",
            "Publication Status": "Статус публикации",
            "Client Status": "Статус заказчика",
            "Final Purposes": "Конечные цели",
            "Reserved Numbers" : "Зарезервированные номера",
            "Public.Number": "Номер публикации",
            "Upload files": "Загрузка файлов",
            "Download" : "Скачать",
            "Download Excel report": "Скачать отчет в формате Excel",
            "Select a status": "Выберите статус",
            "Client number": "Номер клиента",
            "Select a type": "Выберите тип",
            "Select an Eng.phase": "Выберите этап проектирования",
            "PW Filename": "PW Имя файла",
            "File name": 'Имя файла',
            "Select the customers": "Выберите клиентов",
            "Select the customer": "Выберите клиента",
            "If you do not select any customer": "Если вы не выбрали ни одного клиента,",
            "the document will be published to all the customer related to this project": "документ будет опубликован для всех клиентов, связанных с этим проектом.",
            "the documents will be published to all the customer related to this project": "документы будет опубликован для всех клиентов, связанных с этим проектом.",
            "You are going to add to a folder the following document": "Вы собираетесь добавить в папку следующий документ",
            "You are going to add to a folder the following documents": "Вы собираетесь добавить в папку следующий документы",
            "You are going to remove from a folder the following document": "Вы собираетесь удалить из папки следующий документ",
            "You are going to remove from a folder the following documents": "Вы собираетесь добавить в папку следующий документы",
            "Select a folder": "Выберите папку",
            "New subfolder": "Новая вложенная папка",
            "Edit subfolder": "Редактировать вложенную папку",
            "Remove subfolder": "Удалить вложенную папку",
            "Only one file can be uploaded": "Можно загрузить только один файл",
            "Please select a .zip file": "Пожалуйста, выберите файл .zip",
            "Folder name": "Имя папки",
            "Folder path": "Путь к папке",
            "Number of sheets": "Количество листов",
            "Remove Folder": "Удалить папку",
            "Partner": "Партнер",
            "Published to": "Опубликовано в",
            "Select a customer": "Выберите клиента",
            "Folder created  successfully": "Папка создана успешно",
            "Folder edited  successfully": "Папка успешно отредактирована",
            "Folder deleted successfully": "Папка успешно удалена",
            "Documents successfully added to the folder": "Документы успешно добавлены в папку",
            "Documents successfully removed from the folder": "Документы успешно удалены из папки",
            "Cannot create this folder": "Не удается создать эту папку",
            "Cannot edit this folder": "Невозможно отредактировать эту папку",
            "Cannot add documents to this folder": "Невозможно добавить документы в эту папку",
            "Cannot remove documents from this folder": "Невозможно удалить документы из этой папки",
            "Mail": "Почта",
            "I would like to receive notifications about": "Я хотел бы получать уведомления о",
            "Notifications": "Уведомления",
            "Status changed in EXOD2": "Статус изменен в EXOD2",
            "Supplier publish in AGILE EXOD2": "Публикация поставщика в AGILE EXOD2",
            "Supplier publish in EXOD2": "Публикация поставщика в EXOD2",
            "Reserved numbers created in EXOD2": "Зарезервированные номера, созданные в EXOD2",
            "Publish document in EXOD2": "Опубликовать документ в EXOD2",
            "New comments in EXOD2": "Новые комментарии в EXOD2",
            "Client checker to approve in EXOD2": "Проверка клиента для утверждения в EXOD2",
            "Notification when a TD is created": "Уведомление о создании TD",
            "You are going to delete the folder": "Вы собираетесь удалить папку",
            "Do you want to create a new revision for this document?": "Вы хотите создать новую редакцию для этого документа?"
        }
    },
    fr: {
        translation: {
            "Home": "Accueil",
            "Dashboard": "Tableau de bord",
            "Engineering Orders": "Commandes d'ingénierie",
            "Supplier material": "Matériel du fournisseur",
            "Create Revision": "Créer une révision",
            "Administration": "Administration",
            "Quality Check": "Contrôle de la qualité",
            "Publish": "Publier",
            "Pending": "En attente",
            "Projects configuration": "Configuration des projets",
            "Folders": "Dossiers",
            "Client Number": "Numéro de client",
            "Generators": "Générateurs",
            "Filters": "Filtres",
            "Status": "Statut",
            "Select a Status": "Sélectionnez un statut",
            "From": "De",
            "To": "À",
            "SMS Doc.Number": "Numéro de document",
            "Press enter to search": "Appuyez sur Enter pour effectuer une recherche",
            "Sheet": "Feuille",
            "Revision": "Révision",
            "Agile Number": "Numéro Agile",
            "TD Number": "Numéro TD",
            "Publication Number": "Numéro de publication",
            "Remove PBS": "Enlever PBS",
            "Type": "Type",
            "Discipline": "Discipline",
            "Select a discipline": "Sélectionnez une discipline",
            "Doc Type": "Type de document",
            "Select a doc type": "Sélectionnez un type de document",
            "Eng.Phase": "Phase d'ingénierie",
            "Select an eng.phase": "Sélectionnez une phase de travail",
            "Entity": "Entité",
            "Select an entity": "Sélectionnez une entité",
            "Purpose": "Objectif",
            "Select a purpose": "Sélectionnez un objectif",
            "PW filename": "Nom de fichier PW",
            "commented": "commenté",
            "Commented": "Commenté",
            "Show all indexes": "Afficher toutes les versions",
            "Show TD cover page": "Afficher la page de couverture du TD",
            "Change Status": "Changer le statut",
            "You are going to change the status of the following document": "Vous allez changer le statut du document suivant",
            "You are going to change the status of the following documents": "Vous allez changer le statut du documents suivant",
            "The new status will be": "Le nouveau statut sera",
            "Save": "Enregistrer",
            "Cancel": "Annuler",
            "Edit": "Modifier",
            "Supplier doc number": "Numéro de document du fournisseur",
            "This field is mandatory": "Ce champ est obligatoire",
            "Internal use only": "Usage interne uniquement",
            "Edit document": "Modifier le document",
            "Upload": "Télécharger",
            "Selected document": "Document sélectionné",
            "Selected documents": "Documents sélectionné",
            "Upload  files": "Télécharger les fichiers",
            "Drop the files here, or click to select file to upload.": "Déposez les fichiers ici, ou cliquez pour sélectionner le fichier à télécharger.",
            "File type": "Type de fichier",
            "Attachment": "Pièce jointe",
            "Comment": "Commentaire",
            "Delete": "Supprimer",
            "The document": "Le document", 
            "will be deleted. Are you sure?": "sera supprimé. Vous êtes sûr ?",
            "Attention": "Attention",
            "Download files": "Télécharger des fichiers",
            "There are too many documents to download. Please select a number of documents less than 20": "Il y a trop de documents à télécharger. Veuillez sélectionner un nombre de documents inférieur à 20 ",
            "Download only AGILE files": "Télécharger uniquement les fichiers AGILE",
            "Download comments": "Télécharger les commentaires",
            "Report": "Rapport",
            "Refresh": "Rafraîchir",
            "Approved": "Approuvé",
            "Supplier approved": "Fournisseur approuvé",
            "Approved with comments": "Approuvé avec commentaires",
            "Canceled": "Annulé",
            "Checked": "Vérifié",
            "Declined with comments": "Refusé avec commentaires",
            "Supplier declined": "Fournisseur refusé",
            "Published": "Publié",
            "Supplier published": "Fournisseur publié",
            "Ready for Exodbox": "Prêt pour Exodbox",
            "Saved to Agile": "Enregistré dans Agile",
            "Ready for publishing": "Prêt pour la publication",
            "Uploaded": "Téléchargé",
            "Supplier uploaded": "Fournisseur téléchargé",
            "Waiting for Agile": "En attente pour Agile",
            "Reserved": "Réservé",
            "Details": "Détails",
            "Titles": "Titres",
            "Files": "Fichiers",
            "Comments": "Commentaires",
            "Total sheet": "Total de la feuille",
            "Format": "Format",
            "Scale": "Échelle",
            "Customer revision": "Révision du client",
            "Engineering Element": "Élément d'ingénierie",
            "Td Subject": "Sujet Td",
            "Item Number": "Numéro d'article",
            "Material Number": "Numéro de matériau",
            "Designation": "Désignation",
            "Appendix": "Annexe",
            "Internal order number": "Numéro de commande interne",
            "Customer": "Client",
            "Site": "Site",
            "Project": "Projet",
            "Title": "Titre",
            "Other Revisions": "Autres révisions",
            "(LATEST VERSION)": "(DERNIÈRE VERSION)",
            "Publish to D3": "Publier dans D3",
            "Decline": "Décliner",
            "New": "Nouveau",
            "documents": "documents",
            "Administration panel": "Panneau d'administration",
            "Export users": "Exporter des utilisateurs",
            "New external user": "Nouvel utilisateur externe",
            "Internal users": "Utilisateurs internes",
            "External users": "Utilisateurs externes",
            "Email": "Email",
            "Role": "Rôle",
            "Help": "Aide",
            "Changelog": "Changelog",
            "All notable changes to this project will be documented in this file.": "Toutes les modifications notables apportées à ce projet seront documentées dans ce fichier.",
            "Logout": "Déconnexion",
            "Settings": "Paramètres",
            "Deleting": "Suppression",
            "Cannot upload these files": "Impossible de télécharger ces fichiers",
            "Please retry": "Veuillez réessayer",
            "Files uploaded successfully": "Fichiers téléchargés avec succè",
            "Status changed and files uploaded successfully": "Le statut a changé et les fichiers ont été téléchargés avec succès",
            "File uploaded successfully": "Fichier téléchargé avec succès",
            "Document edited successfully": "Document édité avec succès",
            "Documents edited successfully": "Documents édités avec succès",
            "deleted successfully": "supprimé avec succès",
            "Please refresh the page": "Veuillez rafraîchir la page",
            "Cannot get the documents for this project": "Impossible d'obtenir les documents pour ce projet",
            "Cannot get updated document": "Impossible d'obtenir le document mis à jour",
            "Cannot upload this file": "Impossible de télécharger ce fichier",
            "Cannot change the status": "Impossible de changer le statut",
            "Cannot edit this document": "Impossible de modifier ce document",
            "Cannot delete this file": "Impossible de supprimer ce fichier",
            "Cannot delete": "Impossible de supprimer",
            "Cannot download comments": "Impossible de télécharger les commentaires",
            "Cannot get all document ids": "Impossible d'obtenir tous les identifiants des documents",
            "Downloading comments": "Téléchargement des commentaires",
            "Downloading report": "Téléchargement du rapport",
            "Downloading template": "Téléchargement du modèle",
            "Download completed": "Téléchargement terminé",
            "Cannot download excel report": "Impossible de télécharger le rapport Excel",
            "User permissions saved succesfully": "Permissions de l'utilisateur enregistrées avec succès",
            "User created succesfully": "L'utilisateur a été créé avec succès",
            "Cannot fetch internal app roles": "Impossible de récupérer les rôles d'applications internes",
            "Cannot fetch external app roles": "Impossible de récupérer les rôles des applications externes",
            "Cannot fetch internal users": "Impossible de récupérer les utilisateurs internes",
            "Cannot fetch external users": "Impossible de récupérer les utilisateurs externes",
            "Cannot save users": "Impossible de sauvegarder les utilisateurs",
            "Cannot save internal users": "Impossible de sauvegarder les utilisateurs internes",
            "Cannot save external users": "Impossible de sauvegarder des utilisateurs externes",
            "Cannot create new external user": "Impossible de créer un nouvel utilisateur externe",
            "Cannot download user report": "Impossible de télécharger le rapport d'utilisateur",
            "Comment added successfully": "Commentaire ajouté avec succès",
            "Cannot get the document details": "Impossible d'obtenir les détails du document",
            "Cannot get document revisions": "Impossible d'obtenir les révisions du document",
            "Cannot get the document": "Impossible d'obtenir le document",
            "Cannot add new comment": "Impossible d'ajouter un nouveau commentaire",
            "Cannot download this file": "Impossible de télécharger ce fichier",
            "Cannot get publication data": "Impossible d'obtenir les données de publication",
            "Cannot get client data": "Impossible d'obtenir des données sur les clients",
            "Cannot get final purposes data": "Impossible d'obtenir les données sur les objectifs finaux",
            "Project saved successfully": "Projet enregistré avec succès",
            "Cannot get projects list": "Impossible d'obtenir la liste des projets",
            "Cannot get project purposes": "Impossible d'obtenir les objectifs du projet",
            "Cannot save this project": "Impossible de sauvegarder ce projet",
            "Please select a page": "Veuillez sélectionner une page",
            "Please select a project": "Veuillez sélectionner un projet",
            "Import zip": "Importer un zip",
            "Replace file": "Remplacer le fichier",
            "Expand panel": "Agrandir le panneau",
            "selected": "sélectionné",
            "The file":  "Le fichier", 
            "Publication Status": "Statut de la publication",
            "Client Status": "Statut du client",
            "Final Purposes": "Objectifs finaux",
            "Reserved Numbers" : "Numéros réservés",
            "Public.Number": "Numéro de publication",
            "Upload files": "Télécharger des fichiers",
            "Download" : "Télécharger",
            "Download Excel report": "Télécharger le rapport Excel",
            "Select a status": "Sélectionnez un statut",
            "Client number": "Numéro de client",
            "Select a type": "Sélectionnez un type",
            "Select an Eng.phase": "Sélectionnez une phase d'ingénierie",
            "PW Filename": "PW nom de fichier",
            "File name": 'Nom du fichier',
            "Select the customers": "Sélectionnez les clients",
            "Select the customer": "Sélectionnez le client",
            "If you do not select any customer": "Si vous ne sélectionnez aucun client",
            "the document will be published to all the customer related to this project": "le document sera publié pour tous les clients liés à ce projet.",
            "the documents will be published to all the customer related to this project": "les documents sera publié pour tous les clients liés à ce projet.",
            "You are going to add to a folder the following document": "Vous allez ajouter à un document dans le dossier suivant",
            "You are going to add to a folder the following documents": "Vous allez ajouter à un dossier les documents suivants",
            "You are going to remove from a folder the following document": "Vous allez supprimer un document du dossier suivant",
            "You are going to remove from a folder the following documents": "Vous allez supprimer d'un dossier les documents suivants",
            "Select a folder": "Sélectionnez un dossier",
            "New subfolder": "Nouveau sous-dossier",
            "Edit subfolder": "Modifier un sous-dossier",
            "Remove subfolder": "Supprimer un sous-dossier",
            "Only one file can be uploaded": "Un seul fichier peut être téléchargé",
            "Please select a .zip file": "Veuillez sélectionner un fichier .zip",
            "Folder name": "Nom du dossier",
            "Folder path": "Chemin du dossier",
            "Number of sheets": "Nombre de feuilles",
            "Remove Folder": "Supprimer un dossier",
            "Partner": "Partenaire",
            "Published to": "Publié à",
            "Select a customer": "Sélectionnez un client",
            "Folder created  successfully": "Dossier créé avec succès",
            "Folder edited  successfully": "Dossier modifié avec succès",
            "Folder deleted successfully": "Dossier supprimé avec succès",
            "Documents successfully added to the folder": "Documents ajoutés au dossier avec succès",
            "Documents successfully removed from the folder": "Documents supprimés du dossier avec succès",
            "Cannot create this folder": "Impossible de créer ce dossier",
            "Cannot edit this folder": "Impossible de modifier ce dossier",
            "Cannot add documents to this folder": "Impossible d'ajouter des documents à ce dossier",
            "Cannot remove documents from this folder": "Impossible de supprimer des documents de ce dossier",
            "Mail": "Courrier électronique",
            "I would like to receive notifications about": "Je souhaite recevoir des notifications concernant",
            "Notifications": "Notifications",
            "Status changed in EXOD2": "Changement de statut dans EXOD2",
            "Supplier publish in AGILE EXOD2": "Publication du fournisseur dans AGILE EXOD2",
            "Supplier publish in EXOD2": "Publication du fournisseur dans EXOD2",
            "Reserved numbers created in EXOD2": "Numéros réservés créés dans EXOD2",
            "Publish document in EXOD2": "Publier le document dans EXOD2",
            "New comments in EXOD2": "Nouveaux commentaires dans EXOD2",
            "Client checker to approve in EXOD2": "Contrôle client à approuver dans EXOD2",
            "Notification when a TD is created": "Notification lorsqu'un TD est créé",
            "You are going to delete the folder": "Vous allez supprimer le dossier",
            "Do you want to create a new revision for this document?": "Voulez-vous créer une nouvelle révision pour ce document?"
        }
    },
    de: {
        translation: {
            "Home": "Startseite",
            "Dashboard": "Dashboard",
            "Engineering Orders": "Engineering-Aufträge",
            "Supplier material": "Lieferantenmaterial",
            "Create Revision": "Revision erstellen",
            "Administration": "Verwaltung",
            "Quality Check": "Qualität Prüfung",
            "Publish": "Publizieren",
            "Pending": "Offene",
            "Projects configuration": "Konfiguration der Projekte",
            "Folders": "Ordner",
            "Client Number": "Kunden-Nummer",
            "Generators": "Ersteller",
            "Filters": "Filter",
            "Status": "Status",
            "Select a Status": "Status auswählen",
            "From": "Von",
            "To": "bis",
            "SMS Doc.Number": "Dokumentnummer",
            "Press enter to search": "Drücken Sie die Enter-Taste für die Suche",
            "Sheet": "Blatt",
            "Revision": "Revision:",
            "Agile Number": "Agile Nummer",
            "TD Number": "TD Nummer",
            "Publication Number": "Veröffentlichungsnummer",
            "Remove PBS": "PBS entfernen",
            "Type": "Typ",
            "Discipline": "Disziplin",
            "Select a discipline": "Disziplin auswählen",
            "Doc Type": "Doku-Typ",
            "Select a doc type": "Bitte Doku-Typ auswählen",
            "Eng.Phase": "Eng.Phase",
            "Select an eng.phase": "Eng.Phase auswählen",
            "Entity": "Entität",
            "Select an entity": "Entität auswählen",
            "Purpose": "Zweck",
            "Select a purpose": "Zweck auswählen",
            "PW filename": "PW-Dateiname",
            "commented": "kommentiert:",
            "Commented": "Kommentiert:",
            "Show all indexes": "All Versionen anzeigen",
            "Show TD cover page": "TD-Deckblatt anzeigen",
            "Change Status": "Statusänderung",
            "You are going to change the status of the following document": "Sie werden den Status des folgenden Dokuments ändern",
            "You are going to change the status of the following documents": "Sie werden den Status des folgenden Dokuments ändern",
            "The new status will be": "Der neue Status wird sein",
            "Save": "Speichern",
            "Cancel": "Abbrechen",
            "Edit": "Bearbeiten",
            "Supplier doc number": "Lieferanten-Doku-Nummer",
            "This field is mandatory": "Dieses Feld ist ein Pflichtfeld",
            "Internal use only": "Nur für den internen Gebrauch",
            "Edit document": "Dokument bearbeiten",
            "Upload": "Hochladen",
            "Selected document": "Ausgewähltes Dokument",
            "Selected documents": "Ausgewähltes Dokumente",
            "Upload  files": "Datei hochladen",
            "Drop the files here, or click to select file to upload.": "Fügen Sie die Dateien hier ein, oder klicken Sie auf , um eine Datei zum Hochladen auszuwählen.",
            "File type": "Dateityp",
            "Attachment": "Anhang",
            "Comment": "Bemerkung",
            "Delete": "Löschen",
            "The document": "Das Dokument",
            "will be deleted. Are you sure?": "wird gelöscht. Sind Sie sicher?",
            "Attention": "Aufgepasst",
            "Download files": "Datei herunterladen",
            "There are too many documents to download. Please select a number of documents less than 20": "Es gibt zu viele Dokumente zum Herunterladen Bitte wählen Sie eine Anzahl von Dokumenten unter 20",
            "Recommended maximum download: 100 documents. Click OK to proceed. Note: Exceeding this may increase download time.": "Empfohlener maximaler Download: 100 Dokumente. Klicken Sie auf OK, um fortzufahren. Hinweis: Eine Überschreitung kann die Downloadzeit verlängern.",
            "Download only AGILE files": "Nur AGILE Dateien herunterladen",
            "Download comments": "Kommentare herunterladen",
            "Report": "Bericht",
            "Refresh": "Aktualisieren",
            "Approved": "Genehmigt",
            "Supplier approved": "Lieferant genehmigt",
            "Approved with comments": "Genehmigt mit Kommentaren",
            "Canceled": "Abgebrochen",
            "Checked": "Überprüft",
            "Declined with comments": "Abgelehnt mit Bemerkungen",
            "Supplier declined": "Lieferant abgelehnt",
            "Published": "Publiziert",
            "Supplier published": "Lieferant publiziert",
            "Ready for Exodbox": "Bereit für Exodbox",
            "Saved to Agile": "In Agile gespeichert",
            "Ready for publishing": "Bereit zur Veröffentlichung",
            "Uploaded": "Hochgeladen",
            "Supplier uploaded": "Lieferant hochgeladen",
            "Waiting for Agile": "Warten auf Agile",
            "Reserved": "Reserviert",
            "Details": "Details",
            "Titles": "Titel",
            "Files": "Datei",
            "Comments": "Bemerkungen",
            "Total sheet": "Total Blatt",
            "Format": "Format",
            "Scale": "Skala",
            "Customer revision": "Kundenrevision",
            "Engineering Element": "Engineering-Element",
            "Td Subject": "Td Betreff",
            "Item Number": "Artikelnummer",
            "Material Number": "Materialnummer",
            "Designation": "Benennung",
            "Appendix": "Anhang",
            "Internal order number": "Interne Auftragsnummer",
            "Customer": "Kunde",
            "Site": "Standort",
            "Project": "Projekt",
            "Title": "Titel",
            "Other Revisions": "Andere Revisionen",
            "(LATEST VERSION)": "(LETZTE VERSION)",
            "Publish to D3": "In D3 publizieren",
            "Decline": "Ablehnen",
            "New": "Neu",
            "documents": "Dokumente",
            "Administration panel": "Verwaltungs-Panel",
            "Export users": "Benutzer exportieren",
            "New external user": "Neuer externer Benutzer",
            "Internal users": "Interne Benutzer",
            "External users": "Externe Benutzer",
            "Email": "E-Mail",
            "Role": "Rolle",
            "Help": "Hilfe",
            "Changelog": "Änderungsprotokoll",
            "All notable changes to this project will be documented in this file.": "Alle nennenswerten Änderungen an diesem Projekt werden in dieser Datei dokumentiert.",
            "Logout": "Abmelden",
            "Settings": "Einstellungen",
            "Deleting": "Löschen",
            "Cannot upload these files": "Diese Dateien können nicht hochgeladen werden",
            "Please retry": "Bitte nochmals versuchen",
            "Files uploaded successfully": "Dateien erfolgreich hochgeladen",
            "Status changed and files uploaded successfully": "Status geändert und Dateien erfolgreich hochgeladen",
            "File uploaded successfully": "Datei erfolgreich hochgeladen",
            "Document edited successfully": "Dokument erfolgreich bearbeitet",
            "Documents edited successfully": "Dokumente erfolgreich bearbeitet",
            "deleted successfully": "Erfolgreich gelöscht",
            "Please refresh the page": "Bitte die Seite aktualisieren",
            "Cannot get the documents for this project": "Die Dokumente für dieses Projekt sind nicht verfügbar",
            "Cannot get updated document": "Die aktualisierte Dokumente sind nicht verfügbar",
            "Cannot upload this file": "Diese Datei kann nicht hochgeladen werden",
            "Cannot change the status": "Status kann nicht geändert werden",
            "Cannot edit this document": "Dieses Dokument kann  nicht bearbeitet werden",
            "Cannot delete this file": "Diese Datei kann nicht gelöscht werden",
            "Cannot delete": "Kann nicht gelöscht werden",
            "Cannot download comments": "Kommentare  können nicht heruntergeladen werden",
            "Cannot get all document ids": "Es können nicht alle Dokument-IDs abgerufen werden",
            "Downloading comments": "Kommentare werden heruntergeladen",
            "Downloading report": "Bericht wird heruntergeladen",
            "Downloading template": "Vorlage heruntergeladen",
            "Download completed": "Download abgeschlossen",
            "Cannot download excel report": "Excel Bericht kann nicht heruntergeladen werden",
            "User permissions saved succesfully": "Benutzerberechtigungen erfolgreich gespeichert",
            "User created succesfully": "Benutzer erfolgreich erstellt",
            "Cannot fetch internal app roles": "Interne Anwendungsrollen können nicht abgerufen werden",
            "Cannot fetch external app roles": "Externe Anwendungsrollen können nicht abgerufen werden",
            "Cannot fetch internal users": "Interne Benutzer können nicht abgerufen werden",
            "Cannot fetch external users": "Externe Benutzer können nicht abgerufen werden",
            "Cannot save users": "Benutzer können nicht gespeichert werden",
            "Cannot save internal users": "Interne Benutzer können nicht gespeichert werden",
            "Cannot save external users": "Externe Benutzer können nicht gespeichert werden",
            "Cannot create new external user": "Externer Benutzer kann nicht erstellt werden",
            "Cannot download user report": "Benutzerbericht kann nicht heruntergeladen werden",
            "Comment added successfully": "Kommentar erfolgreich beigefügt",
            "Cannot get the document details": "Die Dokumentendetails können nicht abgerufen werden",
            "Cannot get document revisions": "Die Dokumentenrevisionen können nicht abgerufen werden",
            "Cannot get the document": "Das Dokument kann nicht abgerufen werden",
            "Cannot add new comment": "Kommentar kann nicht hinzugefügt werden",
            "Cannot download this file": "Diese Datei kann nicht heruntergeladen werden",
            "Cannot get publication data": "Publikationsdaten können nicht abgerufen werden",
            "Cannot get client data": "Kundendaten können nicht abgerufen werden",
            "Cannot get final purposes data": "Daten für Endzweck können nicht abgerufen werden",
            "Project saved successfully": "Projekt erfolgreich gespeichert",
            "Cannot get projects list": "Projektliste kann nicht abgerufen werden",
            "Cannot get project purposes": "Projektzweck kann nicht abgerufen werden",
            "Cannot save this project": "Projekt kann nich abgespeichert werden",
            "Please select a page": "Bitte Seite auswählen",
            "Please select a project": "Bitte Projekt auswählen",
            "Import zip": "Zip importieren",
            "Replace file": "Datei ersetzen",
            "Expand panel": "Panel erweitern",
            "selected": "ausgewählt",
            "The file": "Die Datei",
            "Publication Status": "Publikationsstatus",
            "Client Status": "Kundenstatus",
            "Final Purposes": "Finaler Zweck",
            "Reserved Numbers" : "Reservierte Nummern",
            "Public.Number": "Nummer der Veröffentlichung",
            "Upload files": "Dateien hochladen",
            "Download" : "Herunterladen",
            "Download Excel report": "Excel-Bericht herunterladen",
            "Select a status": "Wählen Sie einen Status",
            "Client number": "Nummer des Kunden",
            "Select a type": "Wählen Sie einen Typ",
            "Select an Eng.phase": "Wählen Sie eine Engineering-Phase",
            "PW Filename": "PW Dateiname",
            "File name": 'Dateiname',
            "Select the customers": "Wählen Sie die Kunden",
            "Select the customer": "Wählen Sie den Kunden",
            "If you do not select any customer": "Wenn Sie keinen Kunden auswählen",
            "the document will be published to all the customer related to this project": "wird das Dokument für alle Kunden veröffentlicht, die mit diesem Projekt verbunden sind.",
            "the documents will be published to all the customer related to this project": "wird das Dokumente für alle Kunden veröffentlicht, die mit diesem Projekt verbunden sind.",
            "You are going to add to a folder the following document": "Sie fügen das folgende Dokument zu einem Ordner hinzu",
            "You are going to add to a folder the following documents": "Sie fügen einem Ordner die folgenden Dokumente hinzu",
            "You are going to remove from a folder the following document": "Sie wollen das folgende Dokument aus einem Ordner entfernen",
            "You are going to remove from a folder the following documents": "Sie werden die folgenden Dokumente aus einem Ordner entfernen",
            "Select a folder": "Wählen Sie einen Ordner",
            "New subfolder": "Neuer Unterordner",
            "Edit subfolder": "Unterordner bearbeiten",
            "Remove subfolder": "Unterordner entfernen",
            "Only one file can be uploaded": "Es kann nur eine Datei hochgeladen werden",
            "Please select a .zip file": "Bitte wählen Sie eine .zip-Datei",
            "Folder name": "Name des Ordners",
            "Folder path": "Pfad des Ordners",
            "Number of sheets": "Anzahl der Blätter",
            "Remove Folder": "Ordner entfernen",
            "Partner": "Partner",
            "Published to": "Veröffentlicht an",
            "Select a customer": "Wählen Sie einen Kunden",
            "Folder created  successfully": "Ordner erfolgreich erstellt",
            "Folder edited  successfully": "Ordner erfolgreich bearbeitet",
            "Folder deleted successfully": "Ordner erfolgreich gelöscht",
            "Documents successfully added to the folder": "Dokumente erfolgreich zum Ordner hinzugefügt",
            "Documents successfully removed from the folder": "Dokumente erfolgreich aus dem Ordner entfernt",
            "Cannot create this folder": "Dieser Ordner kann nicht erstellt werden",
            "Cannot edit this folder": "Dieser Ordner kann nicht bearbeitet werden",
            "Cannot add documents to this folder": "Dokumente können nicht zu diesem Ordner hinzugefügt werden",
            "Cannot remove documents from this folder": "Dokumente können nicht aus diesem Ordner entfernt werden",
            "Mail": "E-Mail",
            "I would like to receive notifications about": "Ich möchte Benachrichtigungen erhalten über",
            "Notifications": "Benachrichtigungen",
            "Status changed in EXOD2": "Status in EXOD2 geändert",
            "Supplier publish in AGILE EXOD2": "Lieferant veröffentlicht in AGILE EXOD2",
            "Supplier publish in EXOD2": "Lieferant veröffentlicht in EXOD2",
            "Reserved numbers created in EXOD2": "Reservierte Nummern in EXOD2 erstellt",
            "Publish document in EXOD2": "Dokument in EXOD2 veröffentlichen",
            "New comments in EXOD2": "Neue Kommentare in EXOD2",
            "Client checker to approve in EXOD2": "Kundenprüfung zur Freigabe in EXOD2",
            "Notification when a TD is created": "Benachrichtigung, wenn ein TD erstellt wird",
            "You are going to delete the folder": "Sie werden den Ordner löschen",
            "Do you want to create a new revision for this document?": "Möchten Sie eine neue Revision für dieses Dokument erstellen?"
        }
    },
    it: {
        translation: {
            "Home": "Home",
            "Dashboard": "Dashboard",
            "Engineering Orders": "Ordini di ingegneria",
            "Supplier material": "Materiale del fornitore",
            "Create Revision": "Crea revisione",
            "Administration": "Amministrazione",
            "Quality Check": "Controllo di qualità",
            "Publish": "Pubblica",
            "Pending": "in sospeso",
            "Projects configuration": "Configurazione dei progetti",
            "Folders": "Cartelle",
            "Client Number": "Numero cliente",
            "Generators": "generatori",
            "Filters": "filtri",
            "Status": "status",
            "Select a Status": "seleziona uno status",
            "From": "da",
            "To": "a",
            "SMS Doc.Number": "numero del documento",
            "Press enter to search": "premere invio per cercare",
            "Sheet": "foglio",
            "Revision": "revisione",
            "Agile Number": "numero Agile",
            "TD Number": "numero TD",
            "Publication Number": "numero di pubblicazione",
            "Remove PBS": "rimuovi PBS",
            "Type": "tipo",
            "Discipline": "disciplina",
            "Select a discipline": "seleziona una disciplina",
            "Doc Type": "tipo di documento",
            "Select a doc type": "Seleziona il tipo di documento",
            "Eng.Phase": "fase di ingegneria",
            "Select an eng.phase": "seleziona una fase di ingegneria",
            "Entity": "entità",
            "Select an entity": "seleziona un'entità",
            "Purpose": "scopo",
            "Select a purpose": "seleziona uno scopo",
            "PW filename": "PW filename",
            "Commented": "commentato",
            "Show all indexes": "mostra tutte le versioni",
            "Show TD cover page": "mostra copertina TD",
            "Change Status": "cambia lo status",
            "You are going to change the status of the following document": "stai per cambiare lo status del seguente documento",
            "The new status will be": "il nuovo status sarà",
            "Save": "salva",
            "Cancel": "cancella",
            "Edit": "modifica",
            "Supplier doc number": "numero di documento del fonitore",
            "This field is mandatory": "questo campo è obbligatorio",
            "Internal use only": "solo per uso interno",
            "Edit document": "modifica il documento",
            "Upload": "carica",
            "Selected document": "documento selezionato",
            "Upload  files": "carica files",
            "Drop the files here, or click to select file to upload.": "rilascia qui i files o seleziona il file da caricare",
            "File type": "tipo di file",
            "Attachment": "allegato",
            "Comment": "commento",
            "Delete": "cancella",
            "The document": "Il documento",
            "Attention": "Attenzione",
            "Download files": "scarica I files",
            "There are too many documents to download. Please select a number of documents less than 20": "ci sono troppi documenti da scaricare. Perfavore seleziona meno di 20 documenti",
            "Download only AGILE files": "scarica solo files AGILE",
            "Download comments": "scarica i commenti",
            "Report": "Report",
            "Refresh": "aggiorna",
            "Approved": "approvato",
            "Supplier approved": "fornitore approvato",
            "Approved with comments": "approvato con commenti",
            "Canceled": "cancellato",
            "Checked": "controllato",
            "Declined with comments": "rifiutato con commenti",
            "Supplier declined": "fornitore rifiutato",
            "Published": "pubblicato",
            "Supplier published": "fornitore pubblicato",
            "Ready for Exodbox": "pronto per Exodbox",
            "Saved to Agile": "salvato in Agile",
            "Ready for publishing": "pronto per la pubblicazione",
            "Uploaded": "caricato",
            "Supplier uploaded": "fonitore caricato",
            "Waiting for Agile": "aspettando Agile",
            "Reserved": "riservato",
            "Details": "dettagli",
            "Titles": "titoli",
            "Files": "files",
            "Comments": "commenti",
            "Total sheet": "foglio completo",
            "Format": "formato",
            "Scale": "scala",
            "Customer revision": "revisione del cliente",
            "Engineering Element": "elemento di ingegneria",
            "Td Subject": "oggetto TD",
            "Item Number": "numero dell'articolo",
            "Material Number": "numero del materiale",
            "Designation": "designazione",
            "Appendix": "appendice",
            "Internal order number": "numero ordine interno",
            "Customer": "cliente",
            "Site": "sito",
            "Project": "progetto",
            "Title": "titolo",
            "Other Revisions": "altre revisioni",
            "(LATEST VERSION)": "ultima versione",
            "Publish to D3": "pubblica in D3",
            "Decline": "rifiuta",
            "New": "nuovo",
            "documents": "documenti",
            "Administration panel": "pannello amministrazione",
            "Export users": "esporta gli utenti",
            "New external user": "nuovo utente esterno",
            "Internal users": "utenti interni",
            "External users": "utenti esterni",
            "Email": "email",
            "Role": "ruolo",
            "Help": "aiuto",
            "Changelog": "cambia log",
            "All notable changes to this project will be documented in this file.": "tutti I cambiamenti di rilievo per questo progetto saranno documentati in questo file",
            "Logout": "logout",
            "Settings": "impostazioni",
            "Deleting": "cancellando",
            "Cannot upload these files": "impossibile caricare questi files",
            "Please retry": "riprova perfavore",
            "Files uploaded successfully": "files caricati con successo",
            "Status changed and files uploaded successfully": "status cambiato e files caricati con successo",
            "File uploaded successfully": "file caricato con successo",
            "Document edited successfully": "documento modificato con successo",
            "Documents edited successfully": "documenti modificati con successo",
            "deleted successfully": "eliminato con successo",
            "Please refresh the page": "perfavore aggiorna la pagina",
            "Cannot get the documents for this project": "impossibile ottenere I documenti per questo progetto",
            "Cannot get updated document": "Impossibile ottenere il documento aggiornato",
            "Cannot upload this file": "impossibile caricare questo file",
            "Cannot change the status": "impossibile cambiare lo status",
            "Cannot edit this document": "impossibile modificare questo documento",
            "Cannot delete this file": "impossibile eliminare questo file",
            "Cannot delete": "impossibile eliminare",
            "Cannot download comments": "impossibile scaricare commenti",
            "Cannot get all document ids": "impossibile ottenere tutti gli ID del documento",
            "Downloading comments": "download dei commenti",
            "Downloading report": "download del report",
            "Downloading template": "download del template",
            "Download completed": "download completato",
            "Cannot download excel report": "impossibile scaricare il report in excel",
            "User permissions saved succesfully": "I permessi dell'utente sono stati salvati con successo",
            "User created succesfully": "utente creato con successo",
            "Cannot fetch internal app roles": "Impossibile recuperare i ruoli interni dell'app",
            "Cannot fetch external app roles": "Impossibile recuperare i ruoli esterni dell'app",
            "Cannot fetch internal users": "Impossibile recuperare gli utenti interni",
            "Cannot fetch external users": "Impossibile recuperare gli utenti esterni",
            "Cannot save users": "impossibile salvare gli utenti",
            "Cannot save internal users": "impossibile salvare gli utenti interni",
            "Cannot save external users": "impossibile salvare gli utenti esterni",
            "Cannot create new external user": "impossibile creare un nuovo utente esterno",
            "Cannot download user report": "impossibile scaricare un report per l'utente",
            "Comment added successfully": "commento aggiunto con successo",
            "Cannot get the document details": "I dettagli del documento non sono disponibili",
            "Cannot get document revisions": "le revisioni del documento non sono disponibili",
            "Cannot get the document": "il documento non è disponibile",
            "Cannot add new comment": "impossibile aggiungere un nuovo commento",
            "Cannot download this file": "impossibile scaricare questo file",
            "Cannot get publication data": "impossibile ottenere le informazioni di pubblicazione",
            "Cannot get client data": "impossibile ottenere le informazioni del cliente",
            "Cannot get final purposes data": "Impossibile ottenere i dati sugli scopi finali",
            "Project saved successfully": "Il progetto è stato salvato con successo",
            "Cannot get projects list": "non è possibile avere la lista dei progetti",
            "Cannot get project purposes": "non è possibile avere le finalità dei progetti",
            "Cannot save this project": "non è possibile salvare questo progetto",
            "Please select a page": "Seleziona una pagina perfavore",
            "Please select a project": "Seleziona un progetto perfavore",
            "Import zip": "importa zip",
            "Replace file": "Sostituisci il file",
            "Expand panel": "espandi il pannello",
            "selected": "selezionati",
            "The file": "Il file", 
            "will be deleted. Are you sure?": "sarà cancellato. Sei sicuro?",
            "Publication Status": "status della pubblicazione",
            "Client Status": "status del cliente",
            "Final Purposes": "Scopi finali",
            "Reserved Numbers" : "Numeri Riservati",
            "Public.Number": "Public.Number",
            "Upload files": "Carica files",
            "Download" : "Scarica",
            "Download Excel report": "Scarica Excel report",
            "Select a status": "Seleziona uno status",
            "Client number": "Numero cliente",
            "Select a type": "Seleziona un tipo",
            "Select an Eng.phase": "Seleziona una fase di Ingegneria",
            "PW Filename": "PW Nome file",
            "File name": 'Nome file',
            "Select the customers": "Seleziona i clienti",
            "Select the customer": "Seleziona il cliente",
            "If you do not select any customer": "Se non selezioni alcun cliente",
            "the document will be published to all the customer related to this project": "il documento sarà pubblicato a tutti i clienti relativi a questo progetto",
            "the documents will be published to all the customer related to this project": "i documenti saràanno pubblicati a tutti i clienti relativi a questo progetto",
            "You are going to add to a folder the following document": "Stai per aggiungere a questa cartella il seguente documento",
            "You are going to add to a folder the following documents": "Stai per aggiungere a questa cartella i seguenti documenti",
            "You are going to remove from a folder the following document": "Stai per rimuovere il seguente documento da questa cartella",
            "You are going to remove from a folder the following documents": "Stai per rimuovere i seguenti documenti da questa cartella",
            "Select a folder": "Seleziona una cartella",
            "New subfolder": "Nuova sotto cartella",
            "Edit subfolder": "Modifica sotto cartella",
            "Remove subfolder": "Rimuovi sotto cartella",
            "Only one file can be uploaded": "Solo un file può essere caricato",
            "Please select a .zip file": "Seleziona un file .zip",
            "Folder name": "Nome cartella",
            "Folder path": "Nome percorso",
            "Number of sheets": "Numero di fogli",
            "Remove Folder": "Rimuovi cartella",
            "Partner": "Partner",
            "Published to": "Pubbicato a",
            "Select a customer": "Seleziona un cliente",
            "Folder created successfully": "Cartella creata con successo",
            "Folder edited  successfully": "Cartella modificata con successo",
            "Folder deleted successfully": "Cartella rimossa con successo",
            "Documents successfully added to the folder": "Documenti aggiunti con successo alla cartella",
            "Documents successfully removed from the folder": "Documents rimossi con successo dalla cartella",
            "Cannot create this folder": "Impossibile creare questa cartella",
            "Cannot edit this folder": "Impossibile modificare questa cartella",
            "Cannot add documents to this folder": "Impossibile aggiungere documenti a questa cartella",
            "Cannot remove documents from this folder": "Impossibile rimuovere documenti da questa cartella",
            "Mail": "Mail",
            "I would like to receive notifications about": "Vorrei ricevere notifiche per",
            "Notifications": "Notifiche",
            "Status changed in EXOD2": "Stato cambiato in EXOD2",
            "Supplier publish in AGILE EXOD2": "Fornitore ha pubblicato su AGILE EXOD2",
            "Supplier publish in EXOD2": "Fornitore ha pubblicato su EXOD2",
            "Reserved numbers created in EXOD2": "Numeri riservati creati in EXOD2",
            "Publish document in EXOD2": "Documento pubblicato su EXOD2",
            "New comments in EXOD2": "Nuovi commenti in EXOD2",
            "Client checker to approve in EXOD2": "Client checker to approve in EXOD2",
            "Notification when a TD is created": "Notifica quanto un TD viene creato",
            "You are going to delete the folder": "Stai per cancellare la cartella",
            "Do you want to create a new revision for this document?": "Vuoi creare una nuova revisione per questo documento?"
        }
    }
};