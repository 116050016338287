import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { Checkbox, FontIcon, FontSizes, IDropdownOption, Label, TextField } from 'office-ui-fabric-react';
import { ModalDocumentAction } from '../../../redux/actions/modal';
import { getClassNames } from './index.classNames';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getSelectedProjectUserActions } from '../../../redux/selectors/auth';
import { InternalRoleActionsType, ExternalRoleActionsType } from '../../../utils/costants';

export interface DownloadFilesProps extends RouteComponentProps, WithTranslation {
    downloadForm?: {
        fileName?: string,
        isParent?: boolean,
        useClientNumberAsFilename?: boolean
    }
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    setOptional: typeof ModalDocumentAction.setOptional,
    setContextItemProperty: typeof ModalDocumentAction.setContextItemProperty
}
class DownloadFiles extends React.Component<DownloadFilesProps> {
    constructor(props: DownloadFilesProps) {
        super(props);   
        this.state = {

        }
    }

    _onRenderCategoryOption = (option?: IDropdownOption): JSX.Element => {
        const styles = getClassNames();
        return (
            <div key={(option) ? option.key : undefined} className={styles.dropDownItem}>
                {option && (option as any).icon && <FontIcon style={{marginRight: 10, fontSize: FontSizes.xLarge, color: (option) ? (option as any).color : undefined}} iconName={(option) ? (option as any).icon : undefined} />}
                <span>{(option) ? option.text : undefined}</span>
            </div>     
        );
    };

    _onRenderCategoryTitle = (options?: IDropdownOption[]): JSX.Element => {
        const option = (options && options.length > 0) ? options[0] : undefined;
        return this._onRenderCategoryOption(option);
    };

    render(){
        const { downloadForm, userProjectActions, t, setContextItemProperty } = this.props;
        let isPartner = _.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId');
        return (
            <>
             
                <>
                    <TextField 
                        label={t('File name')} 
                        autoComplete={'off'} 
                        value={(downloadForm && downloadForm.fileName) || ''} 
                        onChange={(e, value) => { 
                            setContextItemProperty({id: 'fileName', name: 'fileName', value})
                        }}
                        styles={{root: {minWidth: 260}}}
                    />
                </>
                                                                                
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    downloadForm: (state) => (getGenericModal('fileName')(state)) ? (getGenericModal('fileName')(state) as any).contextItem : undefined,
    userProjectActions: getSelectedProjectUserActions,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setOptional: ModalDocumentAction.setOptional,
        setContextItemProperty: ModalDocumentAction.setContextItemProperty
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(DownloadFiles)));