import * as React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './index.classNames';
import _ from 'lodash';
import { TagItem } from 'office-ui-fabric-react/lib/Pickers';
import { DefaultButton, Text,} from 'office-ui-fabric-react';
import { CommandActions, DocumentActions } from '../../../redux/actions/filters';
import { CommandActions as DocumentsCommandActions, DocumentActions as DocumentsDocumentActions} from '../../../redux/actions/documents';
import { getFilters } from '../../../redux/reducers/filters';
import { Filters } from '../../../models/filters';
import { formatDate, getHistoryName, getPurposeLabel } from '../../../utils/functions';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../../redux/reducers/project';
import { getTheme } from '../../../utils/costants';

export interface FiltersProps extends RouteComponentProps, WithTranslation {
    filters: Filters,
    darkModeEnabled: boolean,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
    setFilterValue: typeof DocumentActions.setFilterValue,
    resetFilters: typeof CommandActions.resetFilters,
}


class SelectedFilters extends React.Component<FiltersProps> {
    debouncedReloadItems: Function
    constructor(props: FiltersProps) {
        super(props);
        
        this.debouncedReloadItems = _.debounce(() => {
            const { fetchDocuments } = this.props;
            fetchDocuments({});
		}, 500);
    }
    
    resetStringFilter = (name: string) => {
        const { setFilterValue } = this.props;
        setFilterValue({ name, value: undefined });
        this.debouncedReloadItems();
    }
    
    resetBooleanFilter = (name: string) => {
        const { setFilterValue } = this.props;
        setFilterValue({ name, value: false });
        this.debouncedReloadItems();
    }
    
    resetArraySelected = (name: string) => {
        const { setFilterValue } = this.props;
        setFilterValue({ name, value: [] });
        this.debouncedReloadItems();
    }
    
    clearFilters = () => {
        const { resetFilters } = this.props;
        resetFilters();
        this.debouncedReloadItems();
    }
    
    render() {
        const { filters, history, t, darkModeEnabled } = this.props;
        const styles = getClassNames(false, getTheme(darkModeEnabled));
        const historyName = getHistoryName(history);
        const showStatusFilter = historyName === 'dashboard' || historyName === 'reservedNumbers';
        const showIsForInternalUseFilter = historyName !== 'reservedNumbers' && historyName !== 'supplierMaterial';
        return (
            <div style={{ display: "flex", flexWrap: 'wrap', fontSize: 14, fontWeight: 400, marginTop: 5}}>
                { showStatusFilter && filters.status.length > 0 && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetArraySelected("status"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Status')}: </Text><Text>{(filters.status.length > 0) ? _.join(_.map(filters.status, s  => s.description), ', ') : ''}</Text></TagItem>}
                {!!filters._generic && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("_generic"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Generic search')}: </Text><Text>{filters._generic}</Text></TagItem>}
                {filters.documentNumber.length > 0 && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetArraySelected("documentNumber"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('SMS Doc.Number')}: </Text><Text>{_.join(filters.documentNumber, ', ')}</Text></TagItem>}
                {filters.tosId.length > 0 && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetArraySelected("tosId"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('TOS Node Id')}: </Text><Text>{_.join(filters.tosId, ', ')}</Text></TagItem>}
                {!!filters.sheet && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("sheet"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Sheet')}: </Text><Text>{filters.sheet}</Text></TagItem>}
                {!!filters.documentIndex && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("documentIndex"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Index')}: </Text><Text>{filters.documentIndex}</Text></TagItem>}
                {!!filters.clientNumber && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("clientNumber"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Client Number')}: </Text><Text>{filters.clientNumber}</Text></TagItem>}
                {!!filters.tdNumber && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("tdNumber"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('TD Number')}: </Text><Text>{filters.tdNumber}</Text></TagItem>}
                {!!filters.transmittalId && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("transmittalId"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Transmittal Id')}: </Text><Text>{filters.transmittalId}</Text></TagItem>}
                {!!filters.transmittalNumber && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("transmittalNumber"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Transmittal N°')}: </Text><Text>{filters.transmittalNumber}</Text></TagItem>}
                {!!filters.pbs && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("pbs"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>PBS: </Text><Text>{filters.pbs.name}</Text></TagItem>}
                {!!filters.type && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("type"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Type')}: </Text><Text>{filters.type.name}</Text></TagItem>}
                {!!filters.discipline && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("discipline"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Discipline')}: </Text><Text>{filters.discipline.code}</Text></TagItem>}
                {!!filters.docType && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("docType"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Doc.Type')}: </Text><Text>{filters.docType.code}</Text></TagItem>}
                {!!filters.purpose && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("purpose"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t(getPurposeLabel(historyName))}: </Text><Text>{filters.purpose.name}</Text></TagItem>}
                {!!filters.entity && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("entity"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Entity')}: </Text><Text>{filters.entity.name}</Text></TagItem>}
                {!!filters.engPhase && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("engPhase"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Eng.Phase')}: </Text><Text>{filters.engPhase.name}</Text></TagItem>}
                {!!filters.from && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("from"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('From')}: </Text><Text>{formatDate(filters.from)}</Text></TagItem>}
                {!!filters.to && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("to"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('To')}: </Text><Text>{formatDate(filters.to)}</Text></TagItem>}
                {!!filters.pwFilename && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("pwFilename"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('PW Filename')}: </Text><Text>{filters.pwFilename}</Text></TagItem>}
                {filters.commented === true && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetBooleanFilter("commented"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Commented')}: </Text><Text>Yes</Text></TagItem>}
                {showIsForInternalUseFilter && filters.isForInternalUse === true && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetBooleanFilter("isForInternalUse"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Show TD cover page')}: </Text><Text>{t('Yes')}</Text></TagItem>}
                {filters.showAllVersions === true && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetBooleanFilter("showAllVersions"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Show all indexes')}: </Text><Text>{t('Yes')}</Text></TagItem>}
                {!!filters.partner && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("partner"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>Partner: </Text><Text>{filters.partner.companyName}</Text></TagItem>}
                {!!filters.customer && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("customer"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>Customer: </Text><Text>{filters.customer.companyName}</Text></TagItem>}
                {!!filters.supplier && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("supplier"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>Supplier: </Text><Text>{filters.supplier.companyName}</Text></TagItem>}
                {!!filters.folder && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("folder"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>Folder: </Text><Text>{filters.folder.description}</Text></TagItem>}
                {!!filters.sapPart && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("sapPart"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('SAP Doc.Part')}: </Text><Text>{filters.sapPart}</Text></TagItem>}
                {!!filters.sapType && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("sapType"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('SAP Doc.Type')}: </Text><Text>{filters.sapType}</Text></TagItem>}
                {!!filters.language && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("language"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Language')}: </Text><Text>{filters.language}</Text></TagItem>}
                {!!filters.fileType && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("fileType"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('File type')}: </Text><Text>{filters.fileType}</Text></TagItem>}
                {!!filters.supplierDocIndex && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("supplierDocIndex"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Supplier Doc.Index')}: </Text><Text>{filters.supplierDocIndex}</Text></TagItem>}
                {!!filters.supplierDocNumber && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("supplierDocNumber"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Supplier Doc.Number')}: </Text><Text>{filters.supplierDocNumber}</Text></TagItem>}
                {!!filters.customerDocIndex && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("customerDocIndex"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Customer Doc.Index')}: </Text><Text>{filters.customerDocIndex}</Text></TagItem>}
                {!!filters.customerDocNumber && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("customerDocNumber"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Customer Doc.Number')}: </Text><Text>{filters.customerDocNumber}</Text></TagItem>}
                {!!filters.projectDocumentNumber && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("projectDocumentNumber"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Project Doc.Number')}: </Text><Text>{filters.projectDocumentNumber}</Text></TagItem>}
                {!!filters.description && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("description"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Description')}: </Text><Text>{filters.description}</Text></TagItem>}
                {!!filters.description2 && <TagItem item={{ key: "", name: "" }} styles={{root: {maxWidth: 2000}}} index={0} onRemoveItem={() => {this.resetStringFilter("description2"); this.debouncedReloadItems();}}><Text style={{fontWeight: 'bold'}}>{t('Description 2')}: </Text><Text>{filters.description2}</Text></TagItem>}
                {((showStatusFilter && filters.status.length > 0) || filters.documentNumber.length > 0 || filters.tosId.length > 0 || !!filters.sheet || !!filters.documentIndex || !!filters.clientNumber || !!filters.tdNumber || !!filters.publication ||
                  !!filters.type|| !!filters.from|| !!filters.to|| !!filters.purpose || !!filters.entity || !!filters.engPhase || !!filters.pwFilename || filters.commented === true || !!filters.pbs || (showIsForInternalUseFilter && filters.isForInternalUse === true) ||
                  !!filters.showAllVersions === true|| !!filters.partner|| !!filters.customer || !!filters.folder || !!filters.fileType || !!filters._generic || !!filters.description || !!filters.description2) &&

                    <DefaultButton styles={{root: styles.clearButton}} text="Reset" onClick={() => this.clearFilters()} />}
            </div>
        );
    }
}


const mapStateToProps = createStructuredSelector({
    filters: getFilters,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        setFilterValue: DocumentActions.setFilterValue,
        resetFilters: CommandActions.resetFilters,
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(SelectedFilters)));
