import { configuration } from "../configuration";

export const isExternalApp: boolean = true;

export const getTheme = (darkModeEnabled: boolean): any => {
  
  if(!isExternalApp && !darkModeEnabled){
    return (
        {
            palette: {
                themePrimary: '#0077be',
                themeLighterAlt: '#f2f9fc',
                themeLighter: '#cee6f5',
                themeLight: '#a5d2ec',
                themeTertiary: '#57a9d9',
                themeSecondary: '#1887c7',
                themeDarkAlt: '#006dac',
                themeDark: '#005c91',
                themeDarker: '#00446b',
                neutralLighterAlt: '#faf9f8',
                neutralLighter: '#f3f2f1',
                neutralLight: '#edebe9',
                neutralQuaternaryAlt: '#e1dfdd',
                neutralQuaternary: '#d0d0d0',
                neutralTertiaryAlt: '#c8c6c4',
                neutralTertiary: '#a19f9d',
                neutralSecondary: '#605e5c',
                neutralPrimaryAlt: '#3b3a39',
                neutralPrimary: '#323130',
                neutralDark: '#201f1e',
                black: '#000000',
                white: '#ffffff',
            }
        }
    );
  }
  else if (!isExternalApp && darkModeEnabled){
    return (
        {
            palette: {
                themePrimary: '#0291e3',
                themeLighterAlt: '#000609',
                themeLighter: '#001724',
                themeLight: '#012b44',
                themeTertiary: '#015788',
                themeSecondary: '#027fc8',
                themeDarkAlt: '#199be6',
                themeDark: '#3aa9ea',
                themeDarker: '#6bbfef',
                neutralLighterAlt: '#262626',
                neutralLighter: '#2f2f2f',
                neutralLight: '#3d3d3d',
                neutralQuaternaryAlt: '#464646',
                neutralQuaternary: '#4d4d4d',
                neutralTertiaryAlt: '#6b6b6b',
                neutralTertiary: '#c8c8c8',
                neutralSecondary: '#d0d0d0',
                neutralPrimaryAlt: '#dadada',
                neutralPrimary: '#ffffff',
                neutralDark: '#f4f4f4',
                black: '#f8f8f8',
                white: '#1c1c1c',
            }
        }
    );
  }
  else if (isExternalApp && !darkModeEnabled){
    return (
        {
            palette: {
                themePrimary: '#760517',
                themeLighterAlt: '#f9f0f2',
                themeLighter: '#e9c5cb',
                themeLight: '#d698a2',
                themeTertiary: '#ac495a',
                themeSecondary: '#861528',
                themeDarkAlt: '#6a0415',
                themeDark: '#590412',
                themeDarker: '#42030d',
                neutralLighterAlt: '#faf9f8',
                neutralLighter: '#f3f2f1',
                neutralLight: '#edebe9',
                neutralQuaternaryAlt: '#e1dfdd',
                neutralQuaternary: '#d0d0d0',
                neutralTertiaryAlt: '#c8c6c4',
                neutralTertiary: '#a19f9d',
                neutralSecondary: '#605e5c',
                neutralPrimaryAlt: '#3b3a39',
                neutralPrimary: '#323130',
                neutralDark: '#201f1e',
                black: '#000000',
                white: '#ffffff',
            }
        }
    );
  }
  else {
    return (
        {
            palette: {
                themePrimary: '#eb4662',
                themeLighterAlt: '#090304',
                themeLighter: '#260b10',
                themeLight: '#46151d',
                themeTertiary: '#8d2a3b',
                themeSecondary: '#ce3e56',
                themeDarkAlt: '#ed5870',
                themeDark: '#ef7085',
                themeDarker: '#f494a4',
                neutralLighterAlt: '#262626',
                neutralLighter: '#2f2f2f',
                neutralLight: '#3d3d3d',
                neutralQuaternaryAlt: '#464646',
                neutralQuaternary: '#4d4d4d',
                neutralTertiaryAlt: '#6b6b6b',
                neutralTertiary: '#c8c8c8',
                neutralSecondary: '#d0d0d0',
                neutralPrimaryAlt: '#dadada',
                neutralPrimary: '#ffffff',
                neutralDark: '#f4f4f4',
                black: '#f8f8f8',
                white: '#1c1c1c',
            }
        }
    );
  }
}

//DOCUMENTS
export const documentsTop = 30;
export const documentsSkip = 30;
export const previewsAllowed = [
    'png', 'jpg', 'jpeg', 'gif', 'bmp', 'pdf', 'csv', 'xlsx', 'docx', 'mp4', 'mp3'
];

// PATHS
export const HistoryPathValues = [
    'landing', 'home', 'folders', 'dashboard', 'qualityCheck', 'publish', 'publishMaterial',
    'pending', 'reservedNumbers', 'supplierMaterial',
    'createRevision', 'clientNumber', 'reporting', 'projectsConfiguration'
] as const;
export type HistoryPathType = (typeof HistoryPathValues)[number];
export const historyPaths = [];

// FILTERS
export const FiltersValues = [
    'status', 'type', 'documentNumber', 'sheet', 'revision', 'transmittalId', 'transmittalNumber', 'documentIndex', 'clientNumber', 'discipline', 'docType', 'folder', 'partner', 'customer',
    'tdNumber', 'publication', 'purpose', 'entity', 'from', 'to', 'engPhase', 'pwFilename', 'commented', 'pbs', 'isForInternalUse', 'showAllVersions',
    'dates_callout', 'sapType', 'sapPart', 'language', 'fileType', 'projectDocumentNumber', 'supplierDocIndex', 'supplierDocNumber', 'tosId', '_generic', 'customerDocIndex', 'customerDocNumber', 'description', 'description2'
] as const;
export type FiltersType = (typeof FiltersValues)[number];

// PBS LEVELS
export const PbsLevelsValues = [
    'Plant', 'Plant Unit', 'Plant Section', 'Equipment'
] as const;
export type PbsLevelsType = (typeof PbsLevelsValues)[number];

// FILE TYPES
export const FileTypesValues = [
    'pdf', 'dwg', 'zip', 'xslx', 'docx', 'ppt'
] as const;

// COLORS
export const colors: string[] = ['#E06060', '#E09860', '#DCE060', '#68E060', '#60E0D4', '#60B7E0', '#6062E0', '#AC60E0', '#E060C9', '#E0AA60', '#A6E060', '#60E077', '#60E0E0', '#E06090'];

// ROLES
export const InternalRolesValues = ['SUPERADMIN', 'ADMINISTRATOR', 'EEOHANDLER', 'PUBLISHER', 'READER', 'NONE'] as const;
export type InternalRolesType = (typeof InternalRolesValues)[number];
export const InternalRoleActionsValues = [ 
    'Enable', 
    'ShowHome',
    'ShowFolders', 'CreateFolders',
    'ShowDashboard','ReadDashboard', 'DeleteDashboard', 'ChangeStatusDashboard', 'EditDashboard', 'UploadAttachmentDashboard', 'UploadCommentFileDashboard', 'InsertTextCommentDashboard',
    'ShowQualityCheck', 'ReadQualityCheck', 'DeleteQualityCheck', 'EditQualityCheck', 'UploadAttachmentQualityCheck', 'UploadCommentFileQualityCheck', 'InsertTextCommentQualityCheck', 'ReadyForPublishQualityCheck',
    'ShowPublish','ReadPublish', 'DeletePublish', 'EditPublish', 'UploadAttachmentPublish', 'UploadCommentFilePublish', 'InsertTextCommentPublish', 'PublishPublish',
    'ShowPending','ReadPending', 'ApprovePending', 'DeclinePending', 'UploadAttachmentPending', 'UploadCommentFilePending', 'InsertTextCommentPending',
    'ShowReservedNumbers', 'ReadReservedNumbers', 'InsertTextCommentReservedNumbers', 'NewSheetReservedNumbers', 'NewRevisionReservedNumbers', 'SelectFileTypeReservedNumbers', 'GenerateNumberAndPartReservedNumbers',
    'ShowSupplierMaterial', 'ReadSupplierMaterial', 'InsertTextCommentSupplierMaterial', 'PublishSupplierMaterial', 'DeclineSupplierMaterial',
    'ShowCreateRevision', 'ReadCreateRevision', 'InsertTextCommentCreateRevision',
    'ShowClientNumber',
] as const;
export type InternalRoleActionsType = (typeof InternalRoleActionsValues)[number];

export const ExternalRolesValues = ['SUPERADMIN', 'APPROVER', 'CHECKER', 'SUPPLIER', 'PARTNER', 'READER', 'NONE'] as const;
export type ExternalRolesType = (typeof ExternalRolesValues)[number];
export const ExternalRoleActionsValues = [
    'Enable', 
    'ShowHome',
    'ShowFolders',
    'ShowDashboard','ReadDashboard', 'DeleteDashboard', 'ChangeStatusDashboard', 'EditDashboard', 'UploadAttachmentDashboard', 'UploadCommentFileDashboard', 'InsertTextCommentDashboard', 'ReserveNumbersDashboard', 'UploadParentDashboard',
    'ShowPublishMaterial', 'ReadPublishMaterial', 'PublishPublishMaterial', 'DeclinePublishMaterial',  'InsertTextCommentPublishMaterial',
    'ShowPending','ReadPending', 'ApprovePending', 'DeclinePending', 'CheckPending', 'UploadAttachmentPending', 'UploadCommentFilePending', 'InsertTextCommentPending',
    'ShowQualityCheck', 'ReadQualityCheck', 'DeleteQualityCheck', 'EditQualityCheck', 'UploadAttachmentQualityCheck', 'UploadCommentFileQualityCheck', 'InsertTextCommentQualityCheck', 'ReadyForPublishQualityCheck', 'DeclineQualityCheck',
    'ShowPublish', 'ReadPublish', 'DeletePublish', 'EditPublish', 'UploadAttachmentPublish', 'UploadCommentFilePublish', 'InsertTextCommentPublish', 'PublishPublish', 'DeclinePublish',
    'ShowReservedNumbers', 'ReadReservedNumbers', 'UploadParentReservedNumbers', 'NewSheetReservedNumbers', 'SelectFileTypeReservedNumbers',
    'FilterDocumentsBySupplierCompany', 'FilterDocumentsByPartnerId', 'FilterDocumentsByCustomerId'
] as const;
export type ExternalRoleActionsType = (typeof ExternalRoleActionsValues)[number];

// APP CONSTANTS
export const appName: string = 'Exod';
export const clientId: string = 'a25aa282-4f11-4acd-aca1-4f0c1ccc51ee';
export const tokenClientId: string = 'https://ExodB2C.onmicrosoft.com/a25aa282-4f11-4acd-aca1-4f0c1ccc51ee';
export const redirectUri: string = configuration.redirect_uri;
export const tenantId: string = 'https://ExodB2C.b2clogin.com/ExodB2C.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN';
export const graphApiScopes: string[] = ['openid'];